import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatButton, MatDialogRef } from '@angular/material';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'src/app/libraries/globals';
import { EmpresasService } from 'src/app/services/empresas/empresas.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Wait } from 'src/app/ui/wait/Wait';

@Component({
  selector: 'comp-empresas-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class EmpresasCreateComponent implements OnInit {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  @ViewChild('ngFormGroup', {static:false})   ngFormGroup   : FormGroupDirective;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  private boolGuardar : boolean = false;
  private objDialogs  : Dialogs = new Dialogs();
  private formGroup   : FormGroup;

  private lstEstados  : Array<any> = [];

  public objData  : any = null;
  public isEdit   : any = null;

  // LOGO
  selectedImage     : boolean = false;
  imageChangedEvent : any = '';
  croppedImage      : any = '';
  extensionImage    : string = '.jpg';

  constructor(private objDialogRef : MatDialogRef<EmpresasCreateComponent>, 
              private objEmpresasService : EmpresasService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.formGroup = new FormGroup({
      'name'                : new FormControl(null, [Validators.required]),
      'razonSocial'         : new FormControl(null, [Validators.required]),
      'rfc'                 : new FormControl(null),
      'telefono'            : new FormControl(null),
      'celular'             : new FormControl(null),
      'email'               : new FormControl(null),
      'direccion'           : new FormControl(null),
      'ciudad'              : new FormControl(null),
      'estado'              : new FormControl(null),
      'codigoPostal'        : new FormControl(null),
      'codigo'              : new FormControl("", [Validators.required])
    });

    if(this.isEdit) {
      this.formGroup.controls["name"].setValue(this.objData.name);
      this.formGroup.controls["razonSocial"].setValue(this.objData.razonSocial);
      this.formGroup.controls["rfc"].setValue(this.objData.rfc);
      this.formGroup.controls["telefono"].setValue(this.objData.telefono);
      this.formGroup.controls["celular"].setValue(this.objData.celular);
      this.formGroup.controls["email"].setValue(this.objData.email);
      this.formGroup.controls["direccion"].setValue(this.objData.direccion);
      this.formGroup.controls["ciudad"].setValue(this.objData.ciudad);
      this.formGroup.controls["estado"].setValue(this.objData.idEstado);
      this.formGroup.controls["codigoPostal"].setValue(this.objData.codigoPostal);
      this.formGroup.controls["codigo"].setValue(this.objData.codigo);
      this.formGroup.controls["codigo"].disable();
    }
  }

  ngAfterViewInit() {
    this.create();
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {

      var file = event.target.files[0];

      var mimeType = file.type;

      if (mimeType.match(/image\/*/) == null) {
        
        event.target.value = "";
        this.toastr.warning('Solo se admiten imagenes', 'Logo', { timeOut: 2000 });
        return;

      }

      var type : string = mimeType;

      this.imageChangedEvent  = event;
      this.extensionImage     = "." + type.substring(6)
      this.selectedImage      = true;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  btnGuardar_clickEvent()
  {
    if(this.formGroup.invalid) {
      this.toastr.warning('Datos incompletos en el formulario', 'Formulario', { timeOut: 2000 });
      this.ngFormGroup.onSubmit(undefined);
      return;
    }

    if(this.isEdit) {
      this.update();
    } else {
      this.store();
    }
    
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
  }

  private create() : void {

    Wait.show(this.mainContainer, "Espere por favor...");

    this.objEmpresasService.create()
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstEstados = objResponse.result.estados;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private store() : void {
    
    this.loading(true);
    
    let objEmpresaStore : any =
    {
      name                : this.formGroup.controls["name"].value,
      razonSocial         : this.formGroup.controls["razonSocial"].value,
      rfc                 : this.formGroup.controls["rfc"].value,
      telefono            : this.formGroup.controls["telefono"].value,
      celular             : this.formGroup.controls["celular"].value,
      email               : this.formGroup.controls["email"].value,
      direccion           : this.formGroup.controls["direccion"].value,
      ciudad              : this.formGroup.controls["ciudad"].value,
      idEstado            : this.formGroup.controls["estado"].value,
      codigoPostal        : this.formGroup.controls["codigoPostal"].value,
      codigo              : this.formGroup.controls["codigo"].value
    };

    this.objEmpresasService.store(objEmpresaStore)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.onChange.emit(objResponse.result);
          this.objDialogRef.close();
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

  private update() : void {

    this.loading(true);

    let objEmpresaUpdate : any =
    {
      idEmpresa           : this.objData.idEmpresa,
      name                : this.formGroup.controls["name"].value,
      razonSocial         : this.formGroup.controls["razonSocial"].value,
      rfc                 : this.formGroup.controls["rfc"].value,
      telefono            : this.formGroup.controls["telefono"].value,
      celular             : this.formGroup.controls["celular"].value,
      email               : this.formGroup.controls["email"].value,
      direccion           : this.formGroup.controls["direccion"].value,
      ciudad              : this.formGroup.controls["ciudad"].value,
      idEstado            : this.formGroup.controls["estado"].value,
      codigoPostal        : this.formGroup.controls["codigoPostal"].value
    };

    this.objEmpresasService.update(objEmpresaUpdate)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {
          
          this.onChange.emit(objResponse.result);
          this.objDialogs.Success(objResponse.title, objResponse.message);
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });

  }

}
