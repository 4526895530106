import { Component, EventEmitter, Inject, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect, MAT_BOTTOM_SHEET_DATA } from '@angular/material';

@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.css']
})
export class CotizacionesIndexFiltrosComponent implements OnInit {

  @ViewChildren('sltFilter') sltFilter : QueryList<MatSelect>;

  @Output("onChange") onChange : EventEmitter<any> = new EventEmitter<any>();

  // DATES FILTER
  public txtDateDesde  = new FormControl();
  public txtDateHasta  = new FormControl();

  // FILTERS
  public sltCotizacionEstatus  = new FormControl();
  public sltEmpresa            = new FormControl();
  public sltEstado             = new FormControl();
  public sltFormaPago          = new FormControl();
  public sltMetodoPago         = new FormControl();
  public sltFactura            = new FormControl();
  public sltIngreso            = new FormControl();
  public sltSaldoPendiente     = new FormControl();
  public sltCreatedAspNetUser  = new FormControl();
  public sltUpdatedAspNetUser  = new FormControl();

  public lstCotizacionesEstatus : Array<any> = [];
  public lstEmpresas            : Array<any> = [];
  public lstEstados             : Array<any> = [];
  public lstFormasPagos         : Array<any> = [];
  public lstMetodosPagos        : Array<any> = [];
  public lstCreatedAspNetUser   : Array<any> = [];
  public lstUpdatedAspNetUser   : Array<any> = [];

  public lstFactura             : Array<any> = [{value : "true", name : "Con factura"}, {value : "false", name : "Sin factura"}];
  public lstIngreso             : Array<any> = [{value : "true", name : "Con fecha de ingreso"}, {value : "false", name : "Sin fecha de ingreso"}];
  public lstSaldoPendiente      : Array<any> = [{value : "true", name : "Pago completo"}, {value : "false", name : "Pago parcial"}];


  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {
    this.lstCotizacionesEstatus = this.data.lstCotizacionesEstatus;
    this.lstEmpresas            = this.data.lstEmpresas;
    this.lstEstados             = this.data.lstEstados;
    this.lstFormasPagos         = this.data.lstFormasPagos;
    this.lstMetodosPagos        = this.data.lstMetodosPagos;
    this.lstFactura             = this.data.lstFactura;
    this.lstIngreso             = this.data.lstIngreso;
    this.lstSaldoPendiente      = this.data.lstSaldoPendiente;
    this.lstCreatedAspNetUser   = this.data.lstCreatedAspNetUser;
    this.lstUpdatedAspNetUser   = this.data.lstUpdatedAspNetUser;

    this.txtDateDesde.setValue(this.data.txtDateDesde);
    this.txtDateHasta.setValue(this.data.txtDateHasta);
    this.sltCotizacionEstatus.setValue(this.data.sltCotizacionEstatus);
    this.sltEmpresa.setValue(this.data.sltEmpresa);
    this.sltEstado.setValue(this.data.sltEstado);
    this.sltFormaPago.setValue(this.data.sltFormaPago);
    this.sltMetodoPago.setValue(this.data.sltMetodoPago);
    this.sltFactura.setValue(this.data.sltFactura);
    this.sltIngreso.setValue(this.data.sltIngreso);
    this.sltSaldoPendiente.setValue(this.data.sltSaldoPendiente);
    this.sltCreatedAspNetUser.setValue(this.data.sltCreatedAspNetUser);
    this.sltUpdatedAspNetUser.setValue(this.data.sltUpdatedAspNetUser);
  }

  // METODOS
  private build() : void {
    this.onChange.emit(this);
  }

}
