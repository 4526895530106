import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatButton, MatDialogRef } from '@angular/material';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { Globals } from 'src/app/libraries/globals';
import { EmpresasService } from 'src/app/services/empresas/empresas.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';

@Component({
  selector: 'comp-empresas-index-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class EmpresasIndexHeaderComponent implements OnInit {

  @ViewChild('btnGuardar', {static:false})  btnGuardar  : MatButton;
  @ViewChild('btnCerrar', {static:false})   btnCerrar   : MatButton;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();

  objData     : any;
  objDialogs  : Dialogs = new Dialogs();
  boolGuardar : boolean = false;
  isHeader    : boolean = true;

  // LOGO
  imageURL: string | ArrayBuffer | null = null;
  selectedFile: File | null = null;
  extensionImage : string = "";

  // PROGRESS BAR
  objProgres =
  {
    uploadProgress  : 0,
    error           : false,
    message         : ""
  }

  constructor(private objDialogRef : MatDialogRef<EmpresasIndexHeaderComponent>, 
              private objEmpresasService : EmpresasService,
              private toastr: ToastrService) { }

  ngOnInit() {
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {

      var file = event.target.files[0];

      var mimeType = file.type;

      event.target.value = "";

      if (mimeType.match(/image\/*/) == null) {
        this.toastr.warning('Solo se admiten imagenes', 'Mensaje', { timeOut: 2000 });
        return;

      }

      var type : string = mimeType;

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageURL = e.target.result;
      };
      reader.readAsDataURL(file);
      this.extensionImage = "." + type.substring(6)
      this.selectedFile = file;
    }
  }

  btnGuardar_clickEvent()
  {
    if(!this.selectedFile) {
      this.toastr.warning('No se ha cargado el archivo', 'Mensaje', { timeOut: 2000 });
      return;
    }
    
    this.store();
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private store() : void {

    this.loading(true);

    let formInstructor = new FormData();

    formInstructor.append("idEmpresa",  this.objData.idEmpresa);
    formInstructor.append("image",      this.selectedFile);
    formInstructor.append("extension",  this.extensionImage);
    formInstructor.append("isHeader",  String(this.isHeader));

    this.objEmpresasService.uploadHeader(formInstructor)
    .subscribe( objResponse => {
      
      if(Globals.validValue(objResponse))
      {
        if(objResponse.status == 'completed'){
          
          if(objResponse.body.session) {
            
            
            if(objResponse.body.action) {
              
              // Success
              this.objDialogRef.close();
              this.onChange.emit(this);
              this.objDialogs.Success(objResponse.body.title, objResponse.body.message);

            } else {

              // ERROR
              this.objProgres.uploadProgress  = 0;
              this.objProgres.error           = true;
              this.objProgres.message         = objResponse.body.message;
              this.loading(false);

            }

          } else {

            // ERROR
            this.objProgres.uploadProgress  = 0;
            this.objProgres.error           = true;
            this.objProgres.message         = objResponse.body.message;
            this.loading(false);

          }
          
        } else if(objResponse.status == 'progress') {

          // PROGRESS
          this.objProgres.uploadProgress = objResponse.uploadProgress;

        }
      }

    }, error => {

      // ERROR
      this.objProgres.uploadProgress  = 0;
      this.objProgres.error           = true;
      this.objProgres.message         = "Ha ocurrido un error inesperado, comuniquese con el administrador";
      this.loading(false);

    });
  }

}
