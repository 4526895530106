import { SelectService } from "../services/components/select.service";
import { LoadingService } from "../services/loading/loading.service";
import { InputPredictiveService } from "../services/components/inputPredictive.service";
import { ClientesService } from "../services/clientes/clientes.service";
import { UsuariosService } from "../services/usuarios/usuarios.service";
import { ConfiguracionesService } from "../services/configuraciones/configuraciones.service";
import { CotizacionesService } from "../services/cotizaciones/cotizaciones.service";
import { EmpresasVendedoresService } from "../services/empresasVendedores/empresasVendedores.service";
import { CuentasBancariasService } from "../services/cuentasBancarias/cuentasBancarias.service";
import { EmpresasService } from "../services/empresas/empresas.service";
import { FormasPagosService } from "../services/formasPagos/formasPagos.service";
import { MetodosPagosService } from "../services/metodosPagos/metodosPagos.service";
import { ServiciosTiposService } from "../services/serviciosTipos/serviciosTipos.service";
import { CotizacionesCondicionesService } from "../services/cotizacionesCondiciones/cotizacionesCondiciones.service";
import { CotizacionesCondicionesTiposService } from "../services/cotizacionesCondicionesTipos/cotizacionesCondicionesTipos.service";
import { ClientesContactosService } from "../services/clientesContactos/clientesContactos.service";
import { CotizacionesComprobantesService } from "../services/cotizacionesComprobantes/cotizacionesComprobantes.service";
import { CotizacionesFicherosService } from "../services/cotizacionesFicheros/cotizacionesFicheros.service";

export const ApplicationServices:any = [
    LoadingService,
    SelectService,

    // C
    ClientesService,
    ClientesContactosService,
    ConfiguracionesService,
    CotizacionesService,
    CotizacionesComprobantesService,
    CotizacionesCondicionesService,
    CotizacionesCondicionesTiposService,
    CotizacionesFicherosService,
    CuentasBancariasService,

    // E
    EmpresasService,
    EmpresasVendedoresService,

    // I
    InputPredictiveService,

    // F
    FormasPagosService,
    MetodosPagosService,

    // p

    // O

    // S
    ServiciosTiposService,

    // U
    UsuariosService
    
]