import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatButton, MatCheckboxChange, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Globals } from 'src/app/libraries/globals';
import { CotizacionesService } from 'src/app/services/cotizaciones/cotizaciones.service';
import { Wait } from 'src/app/ui/wait/Wait';

@Component({
  selector: 'app-cotizaciones-fecha-ingreso',
  templateUrl: './fecha-ingreso.component.html',
  styleUrls: ['./fecha-ingreso.component.css']
})
export class CotizacionesFechaIngresoComponent implements OnInit, OnDestroy {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  @ViewChild('ngFormGroup', {static:false})   ngFormGroup   : FormGroupDirective;
  
  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  boolGuardar : boolean = false;
  formGroup   : FormGroup;
  objData     : any;

  // FILE
  fileBase64  : string = "";
  fileName    : string = "Buscar documento...";
  
  get hasBase64() : boolean {
    return Globals.validStringValue(this.fileBase64);
  }

  _unsubscribeAll: Subject<any>;

  constructor(private objDialogRef : MatDialogRef<CotizacionesFechaIngresoComponent>,
              private objCotizacionesService : CotizacionesService,
              private toastr: ToastrService) 
              {
                this._unsubscribeAll = new Subject();
              }

  ngOnInit() {
    this.formGroup = new FormGroup({
      'hasIngreso'      : new FormControl(false),
      'ingresoFolio'    : new FormControl("", [Validators.required]),
      'ingresoFecha'    : new FormControl("", [Validators.required]),
      'ingresoDiasPago' : new FormControl("", [Validators.required])
    });
  }

  ngOnDestroy() : void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  chkHasIngreso_changeEvent(event : MatCheckboxChange) {
    this.fileBase64 = "";
    this.fileName   = "Buscar documento...";

    if(event.checked) {
      this.formGroup.controls["ingresoFolio"].disable();
      this.formGroup.controls["ingresoFecha"].disable();
    } else {
      this.formGroup.controls["ingresoFolio"].enable();
      this.formGroup.controls["ingresoFecha"].enable();
    }
  }

  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      var mimeType = file.type;

      event.target.value = "";
      
      if(mimeType.match(/application\/pdf/) == null){ 
        this.toastr.info("Solo se admiten archivos PDF", "Mensaje", { timeOut: 2000 }); 
        return; 
      };

      Globals.fileToBase64(file).subscribe((result => {
        this.fileBase64 = result;
        this.fileName   = file.name;
      }));
    }
  }

  btnDeleteFile() {
    this.fileBase64 = "";
    this.fileName   = "Buscar documento...";
  }

  btnGuardar_clickEvent() {
    if(this.formGroup.invalid){
      this.toastr.info("Información de formulario incompleta.", "Formulario", {timeOut: 3000});
      this.ngFormGroup.onSubmit(undefined);
      return;
    }

    this.store();
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private store() : void {
    this.loading(true);

    let objPost : any =
    {
      idCotizacion    : this.objData.idCotizacion,
      fileBase64      : this.fileBase64,
      fileName        : this.fileName,
      hasIngreso      : !this.formGroup.controls["hasIngreso"].value,
      ingresoFolio    : this.formGroup.controls["ingresoFolio"].value,
      ingresoFecha    : this.formGroup.controls["ingresoFecha"].value,
      ingresoDiasPago : this.formGroup.controls["ingresoDiasPago"].value
    };

    this.objCotizacionesService.storeFechaIngreso(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.onChange.emit(this);
          this.toastr.success(objResponse.message, objResponse.title, { timeOut: 2000 });
          this.objDialogRef.close();
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor", { timeOut: 2000 });
      }

    });
  }

}
