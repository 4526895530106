import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Sessions } from 'src/app/libraries/sessions';
import { IReturn } from 'src/app/libraries/ireturn';
import { APP_BASE_HREF } from '@angular/common';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CotizacionesComprobantesService {

  constructor(private http: HttpClient,  @Inject(APP_BASE_HREF) private baseUrl: string) { }

  store(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/Comprobantes/Store", data, Sessions.header());
  }

  storeComplemento(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/Comprobantes/StoreComplemento", data, Sessions.header());
  }

  delete(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/Comprobantes/Delete", data, Sessions.header());
  }

  deleteComplemento(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/Comprobantes/DeleteComplemento", data, Sessions.header());
  }

}