import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Sessions } from 'src/app/libraries/sessions';
import { IReturn } from 'src/app/libraries/ireturn';
import { APP_BASE_HREF } from '@angular/common';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmpresasService {

  constructor(private http: HttpClient,  @Inject(APP_BASE_HREF) private baseUrl: string) { }

  dataSource(varArgs : any = null): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Empresas/DataSource", varArgs, Sessions.header());
  }

  create(): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Empresas/Create", null, Sessions.header());
  }

  store(objEmpresa : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Empresas/Store", objEmpresa, Sessions.header());
  }

  update(objEmpresa : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Empresas/Update", objEmpresa, Sessions.header());
  }

  delete(objEmpresa : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Empresas/Delete", objEmpresa, Sessions.header());
  }

  uploadLogo(objEmpresa : any): Observable<any> {
    return this.http.post<IReturn>(this.baseUrl + "api/Empresas/UploadLogo", objEmpresa, { headers: Sessions.headerFormData().headers, reportProgress: true, observe: 'events'}).pipe(map((event) => {
      
      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return {body : null, status: 'progress', uploadProgress: progress };

        case HttpEventType.Response:
          return {body : event.body, status: 'completed', uploadProgress: 100};
      }
    }));
  }

  uploadHeader(objEmpresa : any): Observable<any> {
    return this.http.post<IReturn>(this.baseUrl + "api/Empresas/UploadHeader", objEmpresa, { headers: Sessions.headerFormData().headers, reportProgress: true, observe: 'events'}).pipe(map((event) => {
      
      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return {body : null, status: 'progress', uploadProgress: progress };

        case HttpEventType.Response:
          return {body : event.body, status: 'completed', uploadProgress: 100};
      }
    }));
  }

}