import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatButton, MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { CotizacionesFicherosService } from 'src/app/services/cotizacionesFicheros/cotizacionesFicheros.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { Wait } from 'src/app/ui/wait/Wait';
import { CotizacionesDocumentosCreateComponent } from './create/create.component';

@Component({
  selector: 'app-cotizaciones-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.css']
})
export class CotizacionesDocumentosComponent implements OnInit, OnDestroy {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();

  objDialogs              : Dialogs = new Dialogs();
  boolGuardar             : boolean = false;
  lstCotizacionesFicheros : Array<any> = [];
  objData                 : any;

  _unsubscribeAll : Subject<any>;

  constructor(private objDialogRef : MatDialogRef<CotizacionesDocumentosComponent>,
              private objCotizacionesFicherosService : CotizacionesFicherosService,
              private dialog : MatDialog,
              private objRouter : Router,
              private toastr: ToastrService) 
              {
                this._unsubscribeAll = new Subject();
              }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getCotizacionesFicheros();
  }

  ngOnDestroy() : void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  btnAdd_clickEvent() {
    const dialogRef = this.dialog.open(CotizacionesDocumentosCreateComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '600px',
      maxHeight : '600px'
    });

    dialogRef.componentInstance.objData = this.objData;
    dialogRef.componentInstance.onChange.subscribe(() => {
      this.getCotizacionesFicheros();
    });
  }

  btnImprimir_clickEvent(item : any) {
    let url = this.objRouter.createUrlTree(['/api/Cotizaciones/Ficheros/Preview/', item.idCotizacionFichero, item.name]);
    window.open(url.toString(), '_blank');
  }

  btnEliminar_clickEvent(item : any) {
    this.objDialogs.Confirm("Eliminar", "¿Seguro que desea eliminar el documento " + item.name + "?", "Aceptar", "Cancelar", () => {
      this.delete(item.idCotizacionFichero);
    }, null);
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Espere por favor...");
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  public getCotizacionesFicheros() : void {
    Wait.show(this.mainContainer, "Espere por favor...");

    let objPost : any =
    {
      idCotizacion : this.objData.idCotizacion
    }

    this.objCotizacionesFicherosService.index(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.lstCotizacionesFicheros = objResponse.result.cotizacionesFicheros;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private delete(idCotizacionFichero : string) : void {
    this.loading(true);

    let objPost : any =
    {
      idCotizacionFichero : idCotizacionFichero
    };

    this.objCotizacionesFicherosService.delete(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.toastr.success(objResponse.message, objResponse.title, { timeOut: 2000 });
          this.getCotizacionesFicheros();
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor", { timeOut: 2000 });
      }

    });
  }

}
