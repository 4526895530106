import { AppComponent } from '../app.component';
import { LoginComponent } from '../login/login.component';
import { DashboardLayoutComponent } from '../dashboard/layout/dashboard-layout/dashboard-layout.component';
import { InicioComponent } from '../dashboard/contents/inicio/inicio.component';
import { RefreshComponent } from '../dashboard/contents/components/refresh/refresh.component';
import { SelectComponent } from '../components/select/select.component';
import { TextInputDateComponent } from '../components/text-input-date/text-input-date.component';
import { DashboardLayoutPasswordComponent } from '../dashboard/layout/dashboard-layout/components/password/password.component';
import { DashboardLayoutSesionComponent } from '../dashboard/layout/dashboard-layout/components/sesion/sesion.component';
import { ClientesCreateComponent } from '../dashboard/contents/clientes/create/create.component';
import { ClientesIndexComponent } from '../dashboard/contents/clientes/index/index.component';
import { ClientesIndexEditComponent } from '../dashboard/contents/clientes/index/edit/edit.component';
import { UsuariosCreateComponent } from '../dashboard/contents/usuarios/create/create.component';
import { UsuariosIndexComponent } from '../dashboard/contents/usuarios/index/index.component';
import { UsuariosIndexEditComponent } from '../dashboard/contents/usuarios/index/edit/edit.component';
import { UsuariosIndexPasswordComponent } from '../dashboard/contents/usuarios/index/password/password.component';
import { UsuariosIndexPrivilegiosComponent } from '../dashboard/contents/usuarios/index/privilegios/privilegios.component';
import { ClientesIndexFiltrosComponent } from '../dashboard/contents/clientes/index/filtros/filtros.component';
import { ConfiguracionesGlobalComponent } from '../dashboard/contents/configuraciones/global/global.component';
import { CotizacionesCreateComponent } from '../dashboard/contents/cotizaciones/create/create.component';
import { CotizacionesIndexComponent } from '../dashboard/contents/cotizaciones/index/index.component';
import { CotizacionesIndexFiltrosComponent } from '../dashboard/contents/cotizaciones/index/filtros/filtros.component';
import { CotizacionesIndexEmailComponent } from '../dashboard/contents/cotizaciones/index/email/email.component';
import { CotizacionesIndexDuplicarComponent } from '../dashboard/contents/cotizaciones/index/duplicar/duplicar.component';
import { CotizacionesIndexDetallesComponent } from '../dashboard/contents/cotizaciones/index/detalles/detalles.component';
import { CotizacionesIndexAprobarCancelarComponent } from '../dashboard/contents/cotizaciones/index/aprobar-cancelar/aprobar-cancelar.component';
import { CuentasBancariasIndexComponent } from '../dashboard/contents/cuentasBancarias/index/index.component';
import { CuentasBancariasCreateComponent } from '../dashboard/contents/cuentasBancarias/create/create.component';
import { EmpresasIndexComponent } from '../dashboard/contents/empresas/index/index.component';
import { EmpresasCreateComponent } from '../dashboard/contents/empresas/create/create.component';
import { FormasPagosIndexComponent } from '../dashboard/contents/formasPagos/index/index.component';
import { FormasPagosCreateComponent } from '../dashboard/contents/formasPagos/create/create.component';
import { MetodosPagosIndexComponent } from '../dashboard/contents/metodosPagos/index/index.component';
import { MetodosPagosCreateComponent } from '../dashboard/contents/metodosPagos/create/create.component';
import { ServiciosTiposIndexComponent } from '../dashboard/contents/serviciosTipos/index/index.component';
import { ServiciosTiposCreateComponent } from '../dashboard/contents/serviciosTipos/create/create.component';
import { ServiciosTiposIndexEditComponent } from '../dashboard/contents/serviciosTipos/index/edit/edit.component';
import { EmpresasIndexUploadLogoComponent } from '../dashboard/contents/empresas/index/upload-logo/upload-logo.component';
import { EmpresasIndexVendedoresComponent } from '../dashboard/contents/empresas/index/vendedores/vendedores.component';
import { EmpresasIndexVendedoresCreateComponent } from '../dashboard/contents/empresas/index/vendedores/create/create.component';
import { CondicionesTiposIndexComponent } from '../dashboard/contents/condicionesTipos/index/index.component';
import { CondicionesTiposCreateComponent } from '../dashboard/contents/condicionesTipos/create/create.component';
import { CondicionesTiposDescripcionesIndexComponent } from '../dashboard/contents/condicionesTipos/descripciones/index/index.component';
import { CondicionesTiposDescripcionesCreateComponent } from '../dashboard/contents/condicionesTipos/descripciones/create/create.component';
import { ClientesContactosComponent } from '../dashboard/contents/clientes/contactos/contactos.component';
import { ClientesContactosCreateComponent } from '../dashboard/contents/clientes/contactos/create/create.component';
import { CotizacionesCargarFacturaComponent } from '../dashboard/contents/cotizaciones/cargar-factura/cargar-factura.component';
import { CotizacionesComprobantesComponent } from '../dashboard/contents/cotizaciones/comprobantes/comprobantes.component';
import { CotizacionesComprobantesComplementoComponent } from '../dashboard/contents/cotizaciones/comprobantes/complemento/complemento.component';
import { CotizacionesComprobantesCreateComponent } from '../dashboard/contents/cotizaciones/comprobantes/create/create.component';
import { CotizacionesFechaIngresoComponent } from '../dashboard/contents/cotizaciones/fecha-ingreso/fecha-ingreso.component';
import { CotizacionesNotaCreditoComponent } from '../dashboard/contents/cotizaciones/nota-credito/nota-credito.component';
import { CotizacionesOrdenCompraComponent } from '../dashboard/contents/cotizaciones/orden-compra/orden-compra.component';
import { CotizacionesOrdenServicioComponent } from '../dashboard/contents/cotizaciones/orden-servicio/orden-servicio.component';
import { CotizacionesDocumentosComponent } from '../dashboard/contents/cotizaciones/documentos/documentos.component';
import { CotizacionesDocumentosCreateComponent } from '../dashboard/contents/cotizaciones/documentos/create/create.component';
import { EmpresasIndexHeaderComponent } from '../dashboard/contents/empresas/index/header/header.component';

export const ApplicationDeclarations: any[] = [
  AppComponent,
  LoginComponent,
  DashboardLayoutComponent,

  // COMPONENTS
  SelectComponent,
  TextInputDateComponent,

  // C
  ClientesCreateComponent,
  ClientesIndexComponent,
  ClientesIndexEditComponent,
  ClientesIndexFiltrosComponent,

  ClientesContactosComponent,
  ClientesContactosCreateComponent,

  ConfiguracionesGlobalComponent,

  CondicionesTiposCreateComponent,
  CondicionesTiposIndexComponent,
  CondicionesTiposDescripcionesIndexComponent,
  CondicionesTiposDescripcionesCreateComponent,

  CotizacionesCargarFacturaComponent,
  CotizacionesComprobantesComponent,
  CotizacionesComprobantesComplementoComponent,
  CotizacionesComprobantesCreateComponent,
  CotizacionesCreateComponent,
  CotizacionesDocumentosComponent,
  CotizacionesDocumentosCreateComponent,
  CotizacionesFechaIngresoComponent,
  CotizacionesIndexComponent,
  CotizacionesIndexAprobarCancelarComponent,
  CotizacionesIndexDuplicarComponent,
  CotizacionesIndexDetallesComponent,
  CotizacionesIndexEmailComponent,
  CotizacionesIndexFiltrosComponent,
  CotizacionesNotaCreditoComponent,
  CotizacionesOrdenCompraComponent,
  CotizacionesOrdenServicioComponent,

  CuentasBancariasIndexComponent,
  CuentasBancariasCreateComponent,

  // D
  DashboardLayoutPasswordComponent,
  DashboardLayoutSesionComponent,

  // E
  EmpresasIndexComponent,
  EmpresasIndexHeaderComponent,
  EmpresasIndexUploadLogoComponent,
  EmpresasIndexVendedoresComponent,
  EmpresasIndexVendedoresCreateComponent,
  EmpresasCreateComponent,
  
  // I
  InicioComponent,

  // F
  FormasPagosIndexComponent,
  FormasPagosCreateComponent,

  // M
  MetodosPagosIndexComponent,
  MetodosPagosCreateComponent,

  // RELOAD
  RefreshComponent,

  // S
  ServiciosTiposIndexComponent,
  ServiciosTiposIndexEditComponent,
  ServiciosTiposCreateComponent,

  // U
  UsuariosCreateComponent,
  UsuariosIndexComponent,
  UsuariosIndexEditComponent,
  UsuariosIndexPasswordComponent,
  UsuariosIndexPrivilegiosComponent
];