import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Sessions } from 'src/app/libraries/sessions';
import { IReturn } from 'src/app/libraries/ireturn';
import { APP_BASE_HREF } from '@angular/common';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CotizacionesService {

  constructor(private http: HttpClient,  @Inject(APP_BASE_HREF) private baseUrl: string) { }

  index(): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/Index", null, Sessions.header());
  }

  dataSource(varArgs : any = null): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/DataSource", varArgs, Sessions.header());
  }

  create(): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/Create", null, Sessions.header());
  }

  store(objCotizacion : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/Store", objCotizacion, Sessions.header());
  }

  edit(objCotizacion : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/Edit", objCotizacion, Sessions.header());
  }

  update(objCotizacion : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/Update", objCotizacion, Sessions.header());
  }

  delete(objCotizacion : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/Delete", objCotizacion, Sessions.header());
  }

  showEvaluacion(objCotizacion : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/ShowEvaluacion", objCotizacion, Sessions.header());
  }

  storeEvaluacionTemporal(objCotizacion : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/StoreEvaluacionTemporal", objCotizacion, Sessions.header());
  }

  storeEvaluacion(objCotizacion : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/StoreEvaluacion", objCotizacion, Sessions.header());
  }

  showCertificados(objCotizacion : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/ShowCertificados", objCotizacion, Sessions.header());
  }

  searchReporte(objCotizacion : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/SearchReporte", objCotizacion, Sessions.header());
  }

  searchReporteParticipante(objCotizacion : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/SearchReporteParticipante", objCotizacion, Sessions.header());
  }

  fileReporteFilter(objCotizacion : any): Observable<Blob> {
    return this.http.post<Blob>(this.baseUrl + "api/Cotizaciones/File/ReporteFilter", objCotizacion, Sessions.headerFile());
  }

  fileReporteFilterParticipante(objCotizacion : any, url : string): Observable<Blob> {
    return this.http.post<Blob>(this.baseUrl + "api/" + url, objCotizacion, Sessions.headerFile());
  }

  sendEmail(objCotizacion : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/SendEmail", objCotizacion, Sessions.header());
  }

  aprobar(objCotizacion : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/Aprobar", objCotizacion, Sessions.header());
  }

  cancelar(objCotizacion : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/Cancelar", objCotizacion, Sessions.header());
  }

  // NUEVAS ADAPTACIONES 08/01/2024
  readXML(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/ReadXML", data, Sessions.headerFormData());
  }

  showFactura(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/ShowFactura", data, Sessions.header());
  }

  storeFactura(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/StoreFactura", data, Sessions.header());
  }

  storeFechaIngreso(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/StoreFechaIngreso", data, Sessions.header());
  }

  getCotizacionesComprobantes(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/GetCotizacionesComprobantes", data, Sessions.header());
  }

  showNotaCredito(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/ShowNotaCredito", data, Sessions.header());
  }

  storeNotaCredito(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/StoreNotaCredito", data, Sessions.header());
  }

  storeOrdenCompra(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/StoreOrdenCompra", data, Sessions.header());
  }

  storeOrdenServicio(data : any): Observable<IReturn> {
    return this.http.post<IReturn>(this.baseUrl + "api/Cotizaciones/StoreOrdenServicio", data, Sessions.header());
  }

  fileReporte(varArgs : any) : Observable<Blob> {
    return this.http.post<Blob>(this.baseUrl + "api/Cotizaciones/File/Reporte", varArgs, Sessions.headerFile());
  }

}