import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatButton, MatDialog, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { CotizacionesService } from 'src/app/services/cotizaciones/cotizaciones.service';
import { Dialogs } from 'src/app/ui/dialogs/dialogs';
import { CotizacionesComprobantesCreateComponent } from './create/create.component';
import { Router } from '@angular/router';
import { Wait } from 'src/app/ui/wait/Wait';
import { CotizacionesComprobantesService } from 'src/app/services/cotizacionesComprobantes/cotizacionesComprobantes.service';
import { CotizacionesComprobantesComplementoComponent } from './complemento/complemento.component';

@Component({
  selector: 'app-cotizaciones-comprobantes',
  templateUrl: './comprobantes.component.html',
  styleUrls: ['./comprobantes.component.css']
})
export class CotizacionesComprobantesComponent implements OnInit, OnDestroy {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;

  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();

  objDialogs                  : Dialogs = new Dialogs();
  boolGuardar                 : boolean = false;
  lstCotizacionesComprobantes : Array<any> = [];
  objData                     : any;

  _unsubscribeAll : Subject<any>;


  saldoTotal      : number = 0;
  saldoPagado     : number = 0;
  saldoPendiente  : number = 0;

  constructor(private objDialogRef : MatDialogRef<CotizacionesComprobantesComponent>,
              private objCotizacionesService : CotizacionesService,
              private objCotizacionesComprobantesService : CotizacionesComprobantesService,
              private dialog : MatDialog,
              private objRouter : Router,
              private toastr: ToastrService) 
              {
                this._unsubscribeAll = new Subject();
              }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getCotizacionesComprobantes();
  }

  ngOnDestroy() : void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  btnAdd_clickEvent() {
    const dialogRef = this.dialog.open(CotizacionesComprobantesCreateComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '600px',
      maxHeight : '400px'
    });

    dialogRef.componentInstance.objData = this.objData;
    dialogRef.componentInstance.onChange.subscribe(() => {
      this.getCotizacionesComprobantes();
      this.onChange.emit(this);
    });
  }

  btnAddComplemento_clickEvent(item : any) {
    const dialogRef = this.dialog.open(CotizacionesComprobantesComplementoComponent, {
      width     : '99%',
      height    : '99%',
      maxWidth  : '600px',
      maxHeight : '400px'
    });

    dialogRef.componentInstance.objData = item;
    dialogRef.componentInstance.onChange.subscribe(() => {
      this.getCotizacionesComprobantes();
      this.onChange.emit(this);
    });
  }

  btnImprimir_clickEvent(item : any) {
    let url = this.objRouter.createUrlTree(['/api/Cotizaciones/comprobantes/preview/', item.idCotizacionComprobante, item.name]);
    window.open(url.toString(), '_blank');
  }

  btnEliminar_clickEvent(item : any) {
    this.objDialogs.Confirm("Eliminar", "¿Seguro que desea eliminar el comprobante con el número de referencia " + item.numeroReferencia + "?", "Aceptar", "Cancelar", () => {
      this.delete(item.idCotizacionComprobante);
    }, null);
  }

  btnComplementoPDF_clickEvent(item : any) {
    let url = this.objRouter.createUrlTree(['/api/Cotizaciones/Comprobantes/Preview/Complemento/', item.idCotizacionComprobante, true, item.complementoPDFName]);
    window.open(url.toString(), '_blank');
  }

  btnComplementoXML_clickEvent(item : any)
  {
    let url = this.objRouter.createUrlTree(['/api/Cotizaciones/Comprobantes/Preview/Complemento/', item.idCotizacionComprobante, false, item.complementoXMLName]);
    window.open(url.toString(), '_blank', "resizable=no, toolbar=no, menubar=no, status=no, directories=no, location=no, width=1000, height=600");
  }

  btnComplementoDelete_clickEvent(item : any)
  {
    this.objDialogs.Confirm("Eliminar", "¿Seguro que desea eliminar el complemento de pago del número de referencia <b>" + item.numeroReferencia + "</b>?", "Aceptar", "Cancelar", () => {
      this.deleteComplemento(item.idCotizacionComprobante);
    }, null);
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Espere por favor...");
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  public getCotizacionesComprobantes() : void {
    Wait.show(this.mainContainer, "Espere por favor...");

    let objPost : any =
    {
      idCotizacion : this.objData.idCotizacion
    }

    this.objCotizacionesService.getCotizacionesComprobantes(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.saldoTotal                   = objResponse.result.saldoTotal;
          this.saldoPagado                  = objResponse.result.saldoPagado;
          this.saldoPendiente               = objResponse.result.saldoPendiente;
          this.lstCotizacionesComprobantes  = objResponse.result.cotizacionesComprobantes;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private delete(idCotizacionComprobante : string) : void {
    this.loading(true);

    let objPost : any =
    {
      idCotizacionComprobante : idCotizacionComprobante
    };

    this.objCotizacionesComprobantesService.delete(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.toastr.success(objResponse.message, objResponse.title, { timeOut: 2000 });
          this.onChange.emit(this);
          this.getCotizacionesComprobantes();
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor", { timeOut: 2000 });
      }

    });
  }

  private deleteComplemento(idCotizacionComprobante : string) : void {
    this.loading(true);

    let objPost : any =
    {
      idCotizacionComprobante : idCotizacionComprobante
    };

    this.objCotizacionesComprobantesService.deleteComplemento(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.toastr.success(objResponse.message, objResponse.title, { timeOut: 2000 });
          this.onChange.emit(this);
          this.getCotizacionesComprobantes();
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor", { timeOut: 2000 });
      }

    });
  }

}
