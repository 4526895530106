import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatButton, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Globals } from 'src/app/libraries/globals';
import { CotizacionesService } from 'src/app/services/cotizaciones/cotizaciones.service';
import { Wait } from 'src/app/ui/wait/Wait';

@Component({
  selector: 'app-cotizaciones-cargar-factura',
  templateUrl: './cargar-factura.component.html',
  styleUrls: ['./cargar-factura.component.css']
})
export class CotizacionesCargarFacturaComponent implements OnInit, OnDestroy {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  
  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  objData     : any;
  boolGuardar : boolean = false;
  objDataXML  = new DataXML();
  onlyView    : boolean = false;

  // FILE XML
  fileXMLBase64 : string = "";
  fileXMLName   : string = "Buscar documento...";
  
  get hasXMLBase64() : boolean {
    return Globals.validStringValue(this.fileXMLBase64);
  }

  // FILE PDF
  filePDFBase64           : string = "";
  filePDFName             : string = "Buscar documento...";
  filePDFNameSinExtension : string = "Buscar documento...";

  get hasPDFBase64() : boolean {
    return Globals.validStringValue(this.filePDFBase64);
  }

  _unsubscribeAll: Subject<any>;
  
  constructor(private objDialogRef : MatDialogRef<CotizacionesCargarFacturaComponent>,
              private objCotizacionesService : CotizacionesService,
              private objRouter : Router,
              private toastr: ToastrService) 
              {
                this._unsubscribeAll = new Subject();
              }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if(this.onlyView) {
      this.showFactura();
    }
  }

  ngOnDestroy() : void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  onSelectFile(event: any, typePDF: boolean) {
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      var mimeType = file.type;

      event.target.value = "";
      
      if(typePDF && mimeType.match(/application\/pdf/) == null){ 
        this.toastr.info("Solo se admiten archivos PDF", "Mensaje", { timeOut: 2000 }); 
        return; 
      };

      if(!typePDF && mimeType.match(/xml\/*/) == null){
        this.toastr.info("Solo se admiten archivos XML", "Mensaje", { timeOut: 2000 });
        return;
      }

      Globals.fileToBase64(file).subscribe((result => {
        if(typePDF){
          this.filePDFBase64  = result;
          this.filePDFName    = file.name;
        }else{
          this.fileXMLBase64  = result;
          this.fileXMLName    = file.name;
          this.viewXML(file);
        }
      }));
    }
  }

  btnDeleteFile(typePDF : boolean) {
    if(typePDF) {
      this.filePDFBase64  = "";
      this.filePDFName    = "Buscar documento...";
    } else {
      this.fileXMLBase64  = "";
      this.fileXMLName    = "Buscar documento...";
    }

    this.objDataXML = new DataXML();
  }

  btnViewPDF_click()
  {
    let url = this.objRouter.createUrlTree(['/api/Cotizaciones/File/Factura/', this.objData.idCotizacion, true, this.filePDFName]);
    window.open(url.toString(), '_blank');
  }

  btnViewXML_clickEvent() : void {
    let url = this.objRouter.createUrlTree(['/api/Cotizaciones/File/Factura/', this.objData.idCotizacion, false, this.fileXMLName]);
    window.open(url.toString(), '_blank', "resizable=no, toolbar=no, menubar=no, status=no, directories=no, location=no, width=1000, height=600");
  }

  btnGuardar_clickEvent() {
    if(!this.hasXMLBase64){
      this.toastr.info("El documento XML es requerido.", "Documento XML", {timeOut: 3000});
      return;
    }
    
    if(!this.hasPDFBase64){
      this.toastr.info("El documento PDF es requerido.", "Documento PDF", {timeOut: 3000});
      return;
    }

    this.store();
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }
  
  private viewXML(fileXML : any) {
    Wait.show(this.mainContainer, "Espere por favor...");
    
    let upload = new FormData();
    
    upload.append("file", fileXML);
    
    this.objCotizacionesService.readXML(upload)
    .subscribe(objResponse => {

      if(objResponse.session) {

        if(objResponse.action) {

          this.objDataXML = objResponse.result;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {
      
      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  public showFactura() : void {
    Wait.show(this.mainContainer, "Espere por favor...");

    let objPost : any =
    {
      idCotizacion : this.objData.idCotizacion
    }

    this.objCotizacionesService.showFactura(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.filePDFName  = objResponse.result.pdfName;
          this.fileXMLName  = objResponse.result.xmlName;

          this.objDataXML.folio               = objResponse.result.folio;
          this.objDataXML.folioFiscal         = objResponse.result.folioFiscal;
          this.objDataXML.emisionFechaNatural = objResponse.result.fechaEmision;
          this.objDataXML.razonSocialReceptor = objResponse.result.receptorRazonSocial;
          this.objDataXML.rfcReceptor         = objResponse.result.receptorRFC;
          this.objDataXML.razonSocialEmisor   = objResponse.result.emisorRazonSocial;
          this.objDataXML.rfcEmisor           = objResponse.result.emisorRFC;
          this.objDataXML.subtotal            = objResponse.result.subtotal;
          this.objDataXML.total               = objResponse.result.total;
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      Wait.hide(this.mainContainer);

    }, error => {

      Wait.hide(this.mainContainer);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor");
      }

    });
  }

  private store() : void {
    this.loading(true);

    let objPost : any =
    {
      idCotizacion  : this.objData.idCotizacion,
      hasFactura    : true,
      filePDFBase64 : this.filePDFBase64,
      filePDFName   : this.filePDFName,
      fileXMLBase64 : this.fileXMLBase64,
      fileXMLName   : this.fileXMLName,
      importe       : 0,
      observaciones : "",
      dataXML       : this.objDataXML
    };

    this.objCotizacionesService.storeFactura(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.onChange.emit(this);
          this.toastr.success(objResponse.message, objResponse.title, { timeOut: 2000 });
          this.objDialogRef.close();
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor", { timeOut: 2000 });
      }

    });
  }

}

export class DataXML {
  public metodoPago           : string = "-";
  public serie                : string = "-";
  public folio                : string = "-";
  public folioFiscal          : string = "-";
  public emisionFecha         : string = "-";
  public emisionFechaNatural  : string = "-";
  public razonSocialReceptor  : string = "-";
  public rfcReceptor          : string = "-";
  public razonSocialEmisor    : string = "-";
  public rfcEmisor            : string = "-";
  public subtotal             : number = 0;
  public total                : number = 0;
}