import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MatButton, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Globals } from 'src/app/libraries/globals';
import { CotizacionesService } from 'src/app/services/cotizaciones/cotizaciones.service';
import { Wait } from 'src/app/ui/wait/Wait';

@Component({
  selector: 'app-cotizaciones-orden-compra',
  templateUrl: './orden-compra.component.html',
  styleUrls: ['./orden-compra.component.css']
})
export class CotizacionesOrdenCompraComponent implements OnInit, OnDestroy {

  @ViewChild('mainContainer', {static:false}) mainContainer : ElementRef;
  @ViewChild('btnGuardar', {static:false})    btnGuardar    : MatButton;
  @ViewChild('btnCerrar', {static:false})     btnCerrar     : MatButton;
  @ViewChild('ngFormGroup', {static:false})   ngFormGroup   : FormGroupDirective;
  
  @Output('onChange') onChange : EventEmitter<any> = new EventEmitter<any>();
  
  boolGuardar : boolean = false;
  objData     : any;

  // FILE
  fileBase64  : string = "";
  fileName    : string = "Buscar documento...";
  
  get hasBase64() : boolean {
    return Globals.validStringValue(this.fileBase64);
  }

  _unsubscribeAll: Subject<any>;

  constructor(private objDialogRef : MatDialogRef<CotizacionesOrdenCompraComponent>,
              private objCotizacionesService : CotizacionesService,
              private toastr: ToastrService) 
              {
                this._unsubscribeAll = new Subject();
              }

  ngOnInit() {

  }

  ngOnDestroy() : void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // EVENTOS
  close()
  {
    if(!this.boolGuardar)
    {
      this.objDialogRef.close();
    }
  }

  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      var mimeType = file.type;

      event.target.value = "";
      
      if(mimeType.match(/application\/pdf/) == null){ 
        this.toastr.info("Solo se admiten archivos PDF", "Mensaje", { timeOut: 2000 }); 
        return; 
      };

      Globals.fileToBase64(file).subscribe((result => {
        this.fileBase64 = result;
        this.fileName   = file.name;
      }));
    }
  }

  btnDeleteFile() {
    this.fileBase64 = "";
    this.fileName   = "Buscar documento...";
  }

  btnGuardar_clickEvent() {
    if(!this.hasBase64){
      this.toastr.info("El documento PDF es requerido.", "Documento PDF", {timeOut: 3000});
      return;
    }

    this.store();
  }

  // METODOS
  private loading(value : boolean) {
    if(value) {

      Wait.show(this.mainContainer, "Guardando...");
      this.btnGuardar.disabled        = true;
      this.btnCerrar.disabled         = true;
      this.boolGuardar                = true;
      this.objDialogRef.disableClose  = true;

    } else{

      Wait.hide(this.mainContainer);
      this.btnGuardar.disabled        = false;
      this.btnCerrar.disabled         = false;
      this.boolGuardar                = false;
      this.objDialogRef.disableClose  = false;

    }
  }

  private store() : void {
    this.loading(true);

    let objPost : any =
    {
      idCotizacion  : this.objData.idCotizacion,
      fileBase64    : this.fileBase64,
      fileName      : this.fileName
    };

    this.objCotizacionesService.storeOrdenCompra(objPost)
    .subscribe( objResponse => {
      
      if(objResponse.session) {

        if(objResponse.action) {

          this.onChange.emit(this);
          this.toastr.success(objResponse.message, objResponse.title, { timeOut: 2000 });
          this.objDialogRef.close();
          
        } else {
          this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
        }
      } else {
        this.toastr.error(objResponse.message, objResponse.title, { timeOut: 2000 });
      }

      this.loading(false);

    }, error => {

      this.loading(false);

      if( error.status == 401) {
        this.toastr.error("La sesión ha finalizado.", "Error de sesión", { timeOut: 2000 });
      } else {
        this.toastr.error("Póngase en contacto con el administrador.", "Error de conexión al servidor", { timeOut: 2000 });
      }

    });
  }

}
